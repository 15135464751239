import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function LineGraph(values) {
  const data = {
    // x-axis label values
    labels: values.dates,
    datasets: [
      {
        label: "portfolio value",
        // y-axis data plotting values
        data: values.values,
        fill: false,
        borderWidth: 3,
        backgroundColor: "rgba(0, 0, 0, 0)",
        borderColor: "rgba(0, 178, 255, 1)",
        responsive: true,
        lineTension: 0.3,
      },
    ],
  }
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 2,
      },
    },
  }

  return (
    <Line
      data={data}
      options={options}
    />
  );
}

export default LineGraph;
