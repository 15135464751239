import React from "react";

class Footer extends React.Component {
  render() {
    return (
        <div className="footercontainer">
          <div className="aboutLink">
            <a href="/about">about</a>
          </div>
          <div className="createdby">
            created by <a href="/mewes">Mewes</a>
          </div>
        </div>
    );
  }
}

export default Footer;
