import React from "react";

class Creator extends React.Component {
  render() {
    return (
        <div className="creator">
          <div className="headline"><h1>hello, I am Mewes 👋</h1></div>
          <p>
            I am a aspiring data engineer and hobby developer from germany.
            
            This is my biggest project so far in which I enjoyed playing around
            with a lot of different exciting technologies.
          </p>
            <div className="greetings">I hope this service is useful to you!</div>
          <br></br>
          <h2>Development</h2>
            My main experience was in data engineering
            so I started with the most familiar concept of building the data pipeline and setting up 
            the database. After that was done I to managed to learn enough web development to setup
            the front- and backend. In the end the project can be broken into these parts:
          <ul>
            <li>
              bulding a data pipeline with a on the Python module <b>Beautiful Soup</b> based webscraper 
              running daily with a cron job
            </li>
            <li>
              setting up my database using <b>MySQL</b>
            </li>
            <li>
              using <b>React</b> to set up the front end. I got familiar with react hooks and the event
              loop after learning the core concepts in the 
              <a href="https://reactjs.org/tutorial/tutorial.html"> official react tutorial</a>
            </li>
            <li>
              used <b>Express</b> to set up a REST API using fetch and async/await to communicate
              between front end and database
            </li>
            <li>
              hosting the project using a single <b>Digital Ocean</b> droplet.
            </li>
          </ul>
          <div>Feel free to contact me for questions and ideas to contact me via my e-mail: <b>mewes@casehodler.com</b></div>
        </div>
    );
  }
}

export default Creator;