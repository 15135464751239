import React from "react";
import LineGraph from "./LineGraph";

class Chart extends React.Component {
  render() {
    return (
      <div className="chart">
        <div className="graph">
          <LineGraph 
            values={this.props.values}
            dates={this.props.dates}
          />
        </div>
      </div>
    );
  }
}

export default Chart;
