import React from "react";

class About extends React.Component {
  render() {
    return (
        <div className="about">
            <h1 className="headline">Your solution to monitoring your CS:GO case investments! 💰</h1>
            <p>
                Tracking investments on the Counter Strike Weapon Case investments 
                has never been easier.
                You don't need to manage spreadsheets anymore and update the item prices
                by hand. 
                Simply create your portfolio on <b>casehodler</b> and watch it grow
                directly in your inbox.
                The best thing: <b>its completely free</b>.
            </p>
            <div className="questions">
                <ul>
                    <li>
                        All CS:GO Weapon Case items prices are taken directly from the
                        Steam marketplace once a day.
                    </li>
                    <li>The available currency (for now) is USD</li>
                    <li>
                        <b>Disclaimer:</b> The displayed prices are in no way 
                        guaranteed to be exact. The purpose is to provide 
                        a estimate how your portfolio value is changing over 
                        time. When selling or buying items always check on the
                        market you are conducting the transaction.
                    </li>
                </ul>
            </div>
            <p>Feel free to contact me for questions and ideas to contact me via my e-mail: <b>mewes@casehodler.de</b></p>
        </div>
    );
  }
}

export default About;