import React from "react";
import "./index.css";
import Chart from "./chart";
import PortoflioPanel from "./portfolioPanel";
import caseNames from "./caseNames";
import Alert from '@mui/material/Alert';

const BASE_URL = "https://api.casehodler.com";
const PAGE_URL = "https://www.casehodler.com";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.casePricesCache = null;
    this.state = {
      serverResponse: null,
      test: "fail",
      caseNameInput: null,
      qtyInput: null,
      currency: "USD",
      cases: [
        {
          name: "Example Case 1",
          qty: 10000,
          prices: [1.3, 1.2, 1.2, 1.3, 1.22, 1.23, 1.3],
          value: 300,
          change: "+200",
          dates: ["2022-03-24", "2022-03-25", "2022-03-26", "2022-03-27", "2022-03-28", "2022-03-29", "2022-03-30"]
        },
        {
          name: "Example Case 2",
          qty: 1000,
          prices: [1.1, 1.2, 1.3, 1.2, 1.2, 1.15, 1.3],
          value: 330.0,
          change: "+20",
          dates: ["2022-03-24", "2022-03-25", "2022-03-26", "2022-03-27", "2022-03-28", "2022-03-29", "2022-03-30"]
        },
      ],
      portfolioValues: [
        630,
        610,
        700,
        620,
        830,
        740,
        850
      ],
      dates: ["2022-03-24", "2022-03-25", "2022-03-26", "2022-03-27", "2022-03-28", "2022-03-29", "2022-03-30"],
      timescale: "7D",
      alerts: {
        duplicateCase: false,
        caseNotFound: false
      },
      emailAlerts: {
        invalidEmail: false,
        emailSubmitted: false,
        emailExists: false
      },
      email: "",
    };
  }

  componentDidMount() {
    document.title = "casehodler";
  }

  handleServerClick = () => {
    fetch(`${BASE_URL}/api`)
      .then((res) => res.json())
      .then((data) => {
        this.setState({test: "success"});
        this.setState({serverResponse: data.message});
      })
  }

  clickTimescale = (timescale) => {
    this.setState({timescale: timescale})
  }

  handleCaseName = (caseNameInput) => {
    this.setState({ 
      caseNameInput: caseNameInput.target.innerText,
      alerts: {
        duplicateCase: false,
        caseNotFound: false,
      }
     });
  };

  handleQty = (qtyInput) => {
    this.setState({ 
      qtyInput: qtyInput.target.value,
      alerts: {
        duplicateCase: false,
        caseNotFound: false,
      }
     });
  };

  handleEmail = (email) => {
    this.setState({
      email: email.target.value,
      emailAlerts: {
        invalidEmail: false,
        emailSubmitted: false,
        emailExists: false,
      }
    });
  }

  
  fetchCasePrices = async(caseName) => {
    return fetch(
      `${BASE_URL}/getCasePrices`,
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({caseName: caseName}),
      }
    )
    .then((res) => res.json())
    .then((data) => {
        return data;
    });
  };

  fetchPortfolio = async(hash) => {
    return fetch(
      `${BASE_URL}/getPortfolio`,
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({hash: hash}),
      }
    )
    .then((res) => res.json())
    .then((data) => {
        return data;
    });
  };

  fetchPortfolioCases = async(hash) => {
    const rows = await this.fetchPortfolio(hash);
    let caseNames = [];
    let caseQty = []
    //find case names
    for (let i=0;i<rows.length;i++){
      if (caseNames.indexOf(rows[i].item)===-1){
        caseNames.push(rows[i].item);
        caseQty.push(rows[i].qty);
      }
    }
    let cases = [];
    let portfolioValues;
    let prices = [];
    let dates = [];
    let values = []; 
    for(let i=0; i<caseNames.length;i++){
      prices = [];
      dates = [];
      values = [];
      for (let j=0; j<rows.length; j++){
        if (rows[j].item === caseNames[i]) {
          dates.push(rows[j].ds.slice(0, 10));
          prices.push(rows[j].buypriceUSD);
          values.push(rows[j].buypriceUSD*rows[j].qty);
        }
      }
      if (portfolioValues == null){ //== is important here to catch empty array!
        portfolioValues = Array(dates.length).fill(0);
      }
      for (let i=0;i<dates.length;i++){
        portfolioValues[i] = portfolioValues[i] + values[i];
      }
      cases = cases.concat([{
        name: caseNames[i],
        qty: caseQty[i],
        prices: prices,
        dates: dates,
        change: this.generateChange2(prices, caseQty[i]),
        value: Number((prices[0]*caseQty[i]).toFixed(3))
      }]);
    }
    this.setState({
      cases: cases,
      portfolioValues: portfolioValues,
      dates: dates,
      hash: hash,
    });
  };

  updatePortfolio = async() =>{
    const hash = this.props.user.toString();
    const cases = this.state.cases;
    console.log(hash);
    console.log(cases);
    //1. delete old stored portfolio
    //2. store new portfolio in db
    fetch(
      `${BASE_URL}/updatePortfolio`,
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          hash: hash,
          cases: cases,
        }),
      }
    )
    .then((res) => res.json())
    .then((data) => {
        return data;
    });
  }

  generateChange = (prices, qty) => {
    const length = prices.length;
    let interval;
    if (this.state.timescale === "7D"){
      interval = 6;
    }else{
      if(this.state.timescale === "30D"){
        interval = 29;
      } else {
        if(this.state.timescale === "90D"){
          interval = 89;
        }
      }
    }
    if (length-1-interval) {}
    const change = prices[length-1]*qty - prices[length-1-interval]*qty;
    let sign;
    if (change > 0){
      sign = "+";
    }else{
      sign = "";
    }
    return sign + Number(change.toFixed(3));
  };
  
  generateChange2 = (prices, qty) => {
    const length = prices.length;
    const change = prices[length-1]*qty - prices[0]*qty;
    let sign;
    if (change > 0){
      sign = "+";
    }else{
      sign = "";
    }
    return sign + Number(change.toFixed(3));
  }

  generateDates = () => {
    const dates = this.state.cases[0].dates;
    this.setState({dates: dates});
  }
  
  handleAddCase = async() => {
    const name = this.state.caseNameInput;
    let names = [];
    this.state.cases.forEach((c)=>{
      names.push(c.name)
    });

    console.log("add case");
    
    if (caseNames.includes(name)){
      console.log("name in caseNames!");
      if (!names.includes(name)){
        console.log("name not already in list!");
        const qty = Number(this.state.qtyInput);
        const rows = await this.fetchCasePrices(name);
        //console.log(rows);
        let prices = [];
        let dates = [];
        for(let i=0; i<rows.length;i++){
          prices.push(rows[i].buypriceUSD);
          dates.push(rows[i].ds.slice(0, 10));
        }
        const value = Number((prices[0]*qty).toFixed(3));
        const change = this.generateChange2(prices, qty);
        this.generateDates();

        const newCases = this.state.cases.concat([
          {
            name: name,
            qty: qty,
            prices: prices,
            value: value,
            change: change,
            dates: dates,
          },
        ]);
        console.log("added case");
        const portfolioValues = this.addPortfolioValue(prices, qty);
        this.setState({
           cases: newCases,
           portfolioValues: portfolioValues
          });
        console.log("set new state");
        
      } else {
        this.setState({
          alerts: {
            duplicateCase: true,
            caseNotFound: false,
          }
        });
      }
    } else {
      this.setState({
        alerts: {
          caseNotFound: true, 
          duplicateCase: false,
        }
      });
    }
  };

  deleteCase = (i) => {
    let newCases = this.state.cases;
    newCases.splice(i, 1);
    this.setState({cases: newCases});
    this.calcPortfolioValue();
  };

  showAlert = () => {
    if (this.state.alerts.caseNotFound===true){
      return (<div className="alert"><Alert severity="error">Case was not found!</Alert></div>);
    }
    if (this.state.alerts.duplicateCase===true){
      return (<div className="alert"><Alert severity="error">Case already in the list!</Alert></div>);
    }
    if (this.props.user!=="false"){
      return;
    }
    return <div className="placeholder"></div>; 
  };
  
  showEmailAlert = () => {
    if (this.state.emailAlerts.invalidEmail===true){
      return (<Alert severity="error">Invalid Email!</Alert>);
    }
    if (this.state.emailAlerts.emailSubmitted===true){
      return (<Alert severity="success">Email submitted!</Alert>);
    }
    if (this.state.emailAlerts.emailExists===true){
      return (<Alert severity="error">e-mail already registered!</Alert>);
    }
    return
    //return <div className="placeholder"></div>; 
  }

  calcPortfolioValue = () => {
    const cases = this.state.cases;
    let portfolioValues = [];
    let sum = 0;
    for (let i=0; i<7; i++){
      cases.forEach((c)=>{
        sum = sum + c.prices[i] * c.qty;
      })
      portfolioValues.push(sum);
      sum = 0;
    }
    this.setState({portfolioValues: portfolioValues});
    return portfolioValues
  }

  addPortfolioValue = (prices, qty) => {
    const portfolioValues = this.state.portfolioValues;
    let newPortfolioValues = []
    for (let i=0; i<prices.length; i++){
      newPortfolioValues.push(portfolioValues[i]+prices[i]*qty);
    }
    return newPortfolioValues;
  }

  submitEmail = () => {
    const email = this.state.email;
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if ( re.test(email) ) {
      //this.setState({
      //  alerts: {
      //    invalidEmail: false,
      //    emailSubmitted: false,
      //}
      //});
      const cases = this.state.cases;
      fetch(
        `${BASE_URL}/addEmail`,
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            email: email,
            cases: cases,
          }),
        }
      )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.emailSuccess){
          console.log(data.hash);
          this.setState({
            emailAlerts: {
              invalidEmail: false,
              emailSubmitted: true,
              emailExists: true
            }
          })
          window.location.href=`${PAGE_URL}/user/${data.hash}`;
        }else{
          this.setState({
            emailAlerts: {
              invalidEmail: false,
              emailSubmitted: false,
              emailExists: true
            }
          })
        }
      });
    }
    else {
      this.setState({
        emailAlerts: {
          invalidEmail: true,
          emailSubmitted: false,
          emailExists: true
        }
      });
    }
  }
  
  render() {
    return (
      <div className="main">
        <div className="content">
          <div className="chart">
            <Chart 
              values={this.state.portfolioValues}
              dates={this.state.dates}
            />
          </div>
          <div className="portfoliopanel">
            <PortoflioPanel
              cases={this.state.cases}
              handleAddCase={this.handleAddCase}
              handleCaseName={this.handleCaseName}
              handleQty={this.handleQty}
              deleteCase={this.deleteCase}
              showAlert={this.showAlert}
              handleEmail={this.handleEmail}
              submitEmail={this.submitEmail}
              showEmailAlert={this.showEmailAlert}
              user={this.props.user}
              fetchPortfolioCases={this.fetchPortfolioCases}
              updatePortfolio={this.updatePortfolio}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Main;
// ==================================
//ReactDOM.render(<Page />, document.getElementById("root"));
