const caseNames = [
  "EMS Katowice 2014 Legends",
  "EMS Katowice 2014 Challengers",
  "Prisma 2 Case",
  "Danger Zone Case",
  "Fracture Case",
  "Prisma Case",
  "Horizon Case",
  "Snakebite Case",
  "CS20 Case",
  "Revolver Case",
  "Clutch Case",
  "2020 RMR Contenders",
  "2020 RMR Challengers",
  "2020 RMR Legends",
  "Shadow Case",
  "Falchion Case",
  "Chroma 3 Case",
  "Operation Wildfire Case",
  "Stockholm 2021 Finalists Autograph Capsule",
  "Stockholm 2021 Champions Autograph Capsule",
  "Spectrum 2 Case",
  "Stockholm 2021 Legends Sticker Capsule",
  "Chroma 2 Case",
  "Gamma Case",
  "Berlin 2019 Returning Challengers Autograph Capsule",
  "Stockholm 2021 Contenders Sticker Capsule",
  "Berlin 2019 Legends Autograph Capsule",
  "Gamma 2 Case",
  "Operation Riptide Case",
  "Sticker Capsule",
  "Berlin 2019 Minor Challengers Autograph Capsule",
  "Operation Vanguard Weapon Case",
  "Spectrum Case",
  "Stockholm 2021 Legends Patch Pack",
  "Chroma Case",
  "Stockholm 2021 Challengers Sticker Capsule",
  "Stockholm 2021 Challengers Patch Pack",
  "Operation Broken Fang Case",
  "Stockholm 2021 Contenders Patch Pack",
  "CS20 Sticker Capsule",
  "Poorly Drawn Capsule",
  "Operation Phoenix Weapon Case",
  "Berlin 2019 Minor Challengers (Holo/Foil)",
  "Perfect World Sticker Capsule 1",
  "Chicken Capsule",
  "Pinups Capsule",
  "Battlefield 2042 Sticker Capsule",
  "Warhammer 40,000 Sticker Capsule",
  "Skill Groups Capsule",
  "Community Capsule 2018",
  "Team Roles Capsule",
  "Slid3 Capsule",
  "Perfect World Sticker Capsule 2",
  "2021 Community Sticker Capsule",
  "Sugarface Capsule",
  "Half-Life: Alyx Sticker Capsule",
  "Enfu Sticker Capsule",
  "Feral Predators Capsule",
  "CS:GO Graffiti Box",
  "Perfect World Graffiti Box",
  "Community Graffiti Box 1",
  "Bestiary Capsule",
  "Halo Capsule",
  "The Boardroom Sticker Capsule",
  "Berlin 2019 Returning Challengers (Holo/Foil)",
  "Shattered Web Case",
  "Dreams & Nightmares Case",
  "Berlin 2019 Legends (Holo/Foil)",
  "Autograph Capsule | Ninjas in Pyjamas | Cluj-Napoca 2015",
  "CS:GO Patch Pack",
  "Half-Life: Alyx Patch Pack",
  "Autograph Capsule | Team SoloMid | Cluj-Napoca 2015",
  "Autograph Capsule | Team eBettle | Cologne 2015",
  "Autograph Capsule | GODSENT | Atlanta 2017",
  "Autograph Capsule | Fnatic | MLG Columbus 2016",
  "Autograph Capsule | Gambit Gaming | MLG Columbus 2016",
  "Autograph Capsule | Fnatic | Cologne 2015",
  "Autograph Capsule | Ninjas in Pyjamas | Cologne 2015",
  "Autograph Capsule | Counter Logic Gaming | MLG Columbus 2016",
  "Autograph Capsule | Ninjas in Pyjamas | MLG Columbus 2016",
  "Autograph Capsule | Splyce | MLG Columbus 2016",
  "Autograph Capsule | Virtus.Pro | Cologne 2015",
  "Autograph Capsule | Team SoloMid | Cologne 2015",
  "Autograph Capsule | Virtus.Pro | Cluj-Napoca 2015",
  "Autograph Capsule | Fnatic | Cluj-Napoca 2015",
  "Autograph Capsule | FaZe Clan | MLG Columbus 2016",
  "Glove Case",
  "Operation Breakout Weapon Case",
  "Autograph Capsule | G2 Esports | Cluj-Napoca 2015",
  "Autograph Capsule | Team EnVyUs | Cologne 2015",
  "Autograph Capsule | Team Kinguin | Cologne 2015",
  "Autograph Capsule | Virtus.Pro | Atlanta 2017",
  "Autograph Capsule | Team Immunity | Cologne 2015",
  "Autograph Capsule | Natus Vincere | MLG Columbus 2016",
  "Autograph Capsule | mousesports | Cologne 2015",
  "Autograph Capsule | Renegades | Cologne 2015",
  "Autograph Capsule | Virtus.Pro | MLG Columbus 2016",
  "Autograph Capsule | Team EnVyUs | Cluj-Napoca 2015",
  "Autograph Capsule | Natus Vincere | Cluj-Napoca 2015",
  "Autograph Capsule | Counter Logic Gaming | Cluj-Napoca 2015",
  "Katowice 2019 Minor Challengers Autograph Capsule",
  "X-Ray P250 Package",
  "Autograph Capsule | Natus Vincere | Cologne 2015",
  "Autograph Capsule | Gambit Gaming | Atlanta 2017",
  "Stockholm 2021 Mirage Souvenir Package",
  "Autograph Capsule | Vexed Gaming | Cluj-Napoca 2015",
  "Autograph Capsule | Flipsid3 Tactics | MLG Columbus 2016",
  "Katowice 2019 Returning Challengers Autograph Capsule",
  "Autograph Capsule | Astralis | MLG Columbus 2016",
  "Stockholm 2021 Nuke Souvenir Package",
  "Stockholm 2021 Dust II Souvenir Package",
  "Stockholm 2021 Vertigo Souvenir Package",
  "Autograph Capsule | G2 Esports | Atlanta 2017",
  "eSports 2014 Summer Case",
  "Autograph Capsule | Flipsid3 Tactics | Cluj-Napoca 2015",
  "London 2018 Minor Challengers Autograph Capsule",
  "Stockholm 2021 Inferno Souvenir Package",
  "Stockholm 2021 Ancient Souvenir Package",
  "Autograph Capsule | SK Gaming | Atlanta 2017",
  "Autograph Capsule | FaZe Clan | Atlanta 2017",
  "Autograph Capsule | Team Liquid | MLG Columbus 2016",
  "Autograph Capsule | Astralis | Atlanta 2017",
  "Winter Offensive Weapon Case",
  "Autograph Capsule | Team EnVyUs | MLG Columbus 2016",
  "Katowice 2019 Legends Autograph Capsule",
  "Autograph Capsule | mousesports | MLG Columbus 2016",
  "Autograph Capsule | Flipsid3 Tactics | Cologne 2015",
  "Huntsman Weapon Case",
  "Autograph Capsule | OpTic Gaming | Atlanta 2017",
  "CS:GO Weapon Case 3",
  "Autograph Capsule | HellRaisers | Atlanta 2017",
  "Autograph Capsule | Cloud9 G2A | Cologne 2015",
  "Autograph Capsule | Team Liquid | Cluj-Napoca 2015",
  "Autograph Capsule | North | Atlanta 2017",
  "Autograph Capsule | Luminosity Gaming | Cluj-Napoca 2015",
  "eSports 2013 Winter Case",
  "Autograph Capsule | Team EnVyUs | Atlanta 2017",
  "Autograph Capsule | Counter Logic Gaming | Cologne 2015",
  "Autograph Capsule | SK Gaming | Cologne 2016",
  "Autograph Capsule | Fnatic | Cologne 2016",
  "London 2018 Returning Challengers Autograph Capsule",
  "Stockholm 2021 Overpass Souvenir Package",
  "Autograph Capsule | Luminosity Gaming | MLG Columbus 2016",
  "Autograph Capsule | Fnatic | Atlanta 2017",
  "Autograph Capsule | Cloud9 | Cluj-Napoca 2015",
  "Autograph Capsule | Luminosity Gaming | Cologne 2015",
  "Autograph Capsule | Flipsid3 Tactics | Atlanta 2017",
  "Autograph Capsule | Gambit Gaming | Cologne 2016",
  "Autograph Capsule | Virtus.Pro | Cologne 2016",
  "Autograph Capsule | OpTic Gaming | Cologne 2016",
  "Autograph Capsule | Ninjas in Pyjamas | Cologne 2016",
  "Autograph Capsule | Team Dignitas | Cluj-Napoca 2015",
  "Autograph Capsule | Flipsid3 Tactics | Cologne 2016",
  "Autograph Capsule | Titan | Cluj-Napoca 2015",
  "Autograph Capsule | Team Liquid | Atlanta 2017",
  "Community Sticker Capsule 1",
  "Tacticians Music Kit Box",
  "Autograph Capsule | FaZe Clan | Cologne 2016",
  "London 2018 Legends Autograph Capsule",
  "Katowice 2019 Legends (Holo/Foil)",
  "London 2018 Train Souvenir Package",
  "London 2018 Minor Challengers (Holo/Foil)",
  "Masterminds Music Kit Box",
  "Berlin 2019 Mirage Souvenir Package",
  "Autograph Capsule | Counter Logic Gaming | Cologne 2016",
  "Boston 2018 Inferno Souvenir Package",
  "Boston 2018 Attending Legends Autograph Capsule",
  "Katowice 2019 Train Souvenir Package",
  "Autograph Capsule | Team Liquid | Cologne 2016",
  "Autograph Capsule | mousesports | Atlanta 2017",
  "Berlin 2019 Inferno Souvenir Package",
  "Boston 2018 Train Souvenir Package",
  "London 2018 Mirage Souvenir Package",
  "Berlin 2019 Train Souvenir Package",
  "London 2018 Returning Challengers (Holo/Foil)",
  "Katowice 2019 Minor Challengers (Holo/Foil)",
  "Boston 2018 Mirage Souvenir Package",
  "Atlanta 2017 Train Souvenir Package",
  "Berlin 2019 Nuke Souvenir Package",
  "Autograph Capsule | Group A (Foil) | Cologne 2015",
  "London 2018 Dust II Souvenir Package",
  "Autograph Capsule | Cloud9 | MLG Columbus 2016",
  "Autograph Capsule | Titan | Cologne 2015",
  "Katowice 2019 Nuke Souvenir Package",
  "Berlin 2019 Dust II Souvenir Package",
  "Berlin 2019 Overpass Souvenir Package",
  "Katowice 2019 Returning Challengers (Holo/Foil)",
  "Krakow 2017 Inferno Souvenir Package",
  "CS:GO Weapon Case 2",
  "London 2018 Legends (Holo/Foil)",
  "DreamHack Cluj-Napoca 2015 Legends (Foil)",
  "Katowice 2019 Dust II Souvenir Package",
  "Autograph Capsule | G2 Esports | MLG Columbus 2016",
  "Berlin 2019 Vertigo Souvenir Package",
  "London 2018 Inferno Souvenir Package",
  "Krakow 2017 Train Souvenir Package",
  "Autograph Capsule | Natus Vincere | Cologne 2016",
  "StatTrak™ Masterminds Music Kit Box",
  "Boston 2018 Returning Challengers Autograph Capsule",
  "StatTrak™ Radicals Box",
  "StatTrak™ Tacticians Music Kit Box",
  "Katowice 2019 Inferno Souvenir Package",
  "Autograph Capsule | G2 Esports | Cologne 2016",
  "Cologne 2016 Train Souvenir Package",
  "ESL One Cologne 2015 Legends (Foil)",
  "Autograph Capsule | Group B (Foil) | Cologne 2015",
  "Boston 2018 Minor Challengers with Flash Gaming Autograph Capsule",
  "Half-Life: Alyx Collectible Pins Capsule",
  "Collectible Pins Capsule Series 3",
  "London 2018 Overpass Souvenir Package",
  "Collectible Pins Capsule Series 1",
  "Collectible Pins Capsule Series 2",
  "MLG Columbus 2016 Legends (Holo/Foil)",
  "London 2018 Cache Souvenir Package",
  "Operation Hydra Case",
  "London 2018 Nuke Souvenir Package",
  "Boston 2018 Cache Souvenir Package",
  "Autograph Capsule | Group C (Foil) | Cologne 2015",
  "Autograph Capsule | Group D (Foil) | Cologne 2015",
  "Autograph Capsule | Team EnVyUs | Cologne 2016",
  "Katowice 2019 Cache Souvenir Package",
  "Boston 2018 Minor Challengers with Flash Gaming (Holo/Foil)",
  "Katowice 2019 Overpass Souvenir Package",
  "Autograph Capsule | Team Dignitas | Cologne 2016",
  "MLG Columbus 2016 Inferno Souvenir Package",
  "MLG Columbus 2016 Challengers (Holo/Foil)",
  "ESL One Cologne 2015 Inferno Souvenir Package",
  "Krakow 2017 Legends (Holo/Foil)",
  "Atlanta 2017 Mirage Souvenir Package",
  "Autograph Capsule | Astralis | Cologne 2016",
  "Atlanta 2017 Dust II Souvenir Package",
  "Autograph Capsule | mousesports | Cologne 2016",
  "MLG Columbus 2016 Mirage Souvenir Package",
  "Autograph Capsule | Legends (Foil) | MLG Columbus 2016",
  "Autograph Capsule | Legends (Foil) | Cluj-Napoca 2015",
  "Katowice 2019 Mirage Souvenir Package",
  "DreamHack Cluj-Napoca 2015 Train Souvenir Package",
  "Cologne 2016 Legends (Holo/Foil)",
  "Krakow 2017 Challengers (Holo/Foil)",
  "DreamHack Cluj-Napoca 2015 Inferno Souvenir Package",
  "Boston 2018 Attending Legends (Holo/Foil)",
  "Krakow 2017 Mirage Souvenir Package",
  "Cologne 2016 Challengers (Holo/Foil)",
  "MLG Columbus 2016 Cache Souvenir Package",
  "Atlanta 2017 Cache Souvenir Package",
  "Krakow 2017 Challengers Autograph Capsule",
  "Sticker Capsule 2",
  "MLG Columbus 2016 Train Souvenir Package",
  "ESL One Cologne 2015 Challengers (Foil)",
  "Autograph Capsule | Natus Vincere | Atlanta 2017",
  "ESL One Cologne 2015 Dust II Souvenir Package",
  "Cologne 2016 Mirage Souvenir Package",
  "ESL One Cologne 2015 Train Souvenir Package",
  "ESL One Katowice 2015 Inferno Souvenir Package",
  "DreamHack Cluj-Napoca 2015 Cache Souvenir Package",
  "Cologne 2016 Cache Souvenir Package",
  "Krakow 2017 Legends Autograph Capsule",
  "Autograph Capsule | mousesports | Cluj-Napoca 2015",
  "Autograph Capsule | Challengers (Foil) | MLG Columbus 2016",
  "Autograph Capsule | Challengers (Foil) | Cluj-Napoca 2015",
  "Boston 2018 Overpass Souvenir Package",
  "ESL One Cologne 2015 Mirage Souvenir Package",
  "Cologne 2016 Dust II Souvenir Package",
  "Boston 2018 Returning Challengers (Holo/Foil)",
  "DreamHack Cluj-Napoca 2015 Dust II Souvenir Package",
  "ESL One Cologne 2015 Cache Souvenir Package",
  "DreamHack Cluj-Napoca 2015 Challengers (Foil)",
  "ESL One Katowice 2015 Mirage Souvenir Package",
  "DreamHack Cluj-Napoca 2015 Mirage Souvenir Package",
  "ESL One Katowice 2015 Dust II Souvenir Package",
  "Atlanta 2017 Overpass Souvenir Package",
  "Atlanta 2017 Nuke Souvenir Package",
  "eSports 2013 Case",
  "Autograph Capsule | Legends (Foil) | Cologne 2016",
  "Operation Bravo Case",
  "Krakow 2017 Overpass Souvenir Package",
  "ESL One Katowice 2015 Cache Souvenir Package",
  "Boston 2018 Nuke Souvenir Package",
  "Krakow 2017 Cache Souvenir Package",
  "Cologne 2016 Nuke Souvenir Package",
  "ESL One Cologne 2014 Legends",
  "Autograph Capsule | Challengers (Foil) | Atlanta 2017",
  "MLG Columbus 2016 Dust II Souvenir Package",
  "ESL One Cologne 2014 Challengers",
  "Atlanta 2017 Challengers (Holo/Foil)",
  "Autograph Capsule | Challengers (Foil) | Cologne 2016",
  "Krakow 2017 Nuke Souvenir Package",
  "CS:GO Weapon Case",
  "MLG Columbus 2016 Overpass Souvenir Package",
  "ESL One Katowice 2015 Nuke Souvenir Package",
  "ESL One Cologne 2014 Inferno Souvenir Package",
  "Atlanta 2017 Legends (Holo/Foil)",
  "ESL One Cologne 2015 Overpass Souvenir Package",
  "Autograph Capsule | Legends (Foil) | Atlanta 2017",
  "DreamHack Cluj-Napoca 2015 Overpass Souvenir Package",
  "ESL One Cologne 2014 Mirage Souvenir Package",
  "DreamHack 2014 Inferno Souvenir Package",
  "DreamHack 2014 Mirage Souvenir Package",
  "Boston 2018 Legends Autograph Capsule",
  "DreamHack 2014 Legends (Holo/Foil)",
  "Boston 2018 Minor Challengers (Holo/Foil)",
  "Cologne 2016 Overpass Souvenir Package",
  "ESL One Cologne 2014 Dust II Souvenir Package",
  "MLG Columbus 2016 Nuke Souvenir Package",
  "DreamHack 2014 Cache Souvenir Package",
  "ESL One Katowice 2015 Overpass Souvenir Package",
  "ESL One Cologne 2014 Nuke Souvenir Package",
  "ESL One Cologne 2014 Overpass Souvenir Package",
  "Boston 2018 Legends (Holo/Foil)",
  "DreamHack 2014 Dust II Souvenir Package",
  "ESL One Katowice 2015 Legends (Holo/Foil)",
  "ESL One Cologne 2014 Cache Souvenir Package",
  "DreamHack 2013 Souvenir Package",
  "Boston 2018 Minor Challengers Autograph Capsule",
  "DreamHack 2014 Overpass Souvenir Package",
  "MLG Columbus 2016 Cobblestone Souvenir Package",
  "ESL One Cologne 2015 Cobblestone Souvenir Package",
  "ESL One Katowice 2015 Challengers (Holo/Foil)",
  "Krakow 2017 Cobblestone Souvenir Package",
  "Boston 2018 Cobblestone Souvenir Package",
  "ESL One Katowice 2015 Cobblestone Souvenir Package",
  "Atlanta 2017 Cobblestone Souvenir Package",
  "EMS One 2014 Souvenir Package",
  "DreamHack Cluj-Napoca 2015 Cobblestone Souvenir Package",
  "Cologne 2016 Cobblestone Souvenir Package",
  "ESL One Cologne 2014 Cobblestone Souvenir Package",
  "DreamHack 2014 Nuke Souvenir Package",
];
export default caseNames;
