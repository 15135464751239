import React from "react";

class Header extends React.Component {
  render() {
    return (
      <div className="header">
        <div className="logo">
          <a href="/">casehodler <span role="img">📈</span>{" "} </a>
          <span className="slogan"> - track your CS:GO case investments!</span>
        </div>
      </div>
    );
  }
}

export default Header;
