import React from "react";
import ReactDOM from "react-dom";
import Header from "./header";
import Footer from "./footer";
import Main from "./main";
import About from "./about";
import Creator from "./creator";
import {Router, Route} from "wouter";
import './index.css';

const App = () => (
    <div className="page">
        <div className="header">
        <Header />
        </div>
        <Route path="/"><Main user="false"/></Route>
        <Route path="/user/:id">{(params)=><Main user={params.id}/>}</Route>
        <Route path="/about"><p><About/></p></Route>
        <Route path="/mewes"><p><Creator/></p></Route>
        <div className="footer">
        <Footer />
        </div>
    </div>
);
// ==================================
ReactDOM.render(<App />, document.getElementById("root"));